import React from 'react';
import config from '../../config';
export default function Footer() {
  return (
    <section id="footer">
      <div className="inner" id="kontakt">


        <h2 className="major">Kontakt</h2>
    {/*    <p>
          Cras mattis ante fermentum, malesuada neque vitae, eleifend erat.
          Phasellus non pulvinar erat. Fusce tincidunt, nisl eget mattis
          egestas, purus ipsum consequat orci, sit amet lobortis lorem lacus in
          tellus. Sed ac elementum arcu. Quisque placerat auctor laoreet.
        </p>*/}
        <div className={"half-holder"}>
          {/*<div className={"div-left"} style={{width:"50%", float:"left"}}>
        <form method="post" action="/#">
          <div className="fields">
            <div className="field">
              <label htmlFor="name">Jméno</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field">
              <label htmlFor="email">E-mail</label>
              <input type="email" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Zpráva</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
          </div>
          <ul className="actions">
            <li>
              <input type="submit" value="Send Message" />
            </li>
          </ul>
        </form>
          </div>*/}
          <div className={"div-right"} style={{width:"50%", float:"left"}}>





            <span>Obchodní firma: <b>GOLDIS Invest s.r.o.</b></span><br/>
            <span>Sídlo: K lindě 701/5, 190 15 Praha 9</span><br/>
            <span>Identifikační číslo: 071 18 970</span><br/>
            <span>Právní forma: Společnost s ručením omezeným</span><br/>
            <br/>
            <span><b>Statutární zástupce</b></span><br/>
            <span>Jednatel:  Kateryna Vepryk<br/>
                Stehlíkova 1092/3, 274 01 Slaný<br/>
                Česká Republika</span><br/><br/>

          </div>
        <div  className={"div-left"}>
          <span>Bankovní spojení: 115-7838100277/0100</span><br/>
          <span>Telefonní spojení: <a href="tel:+420 777 716 122">+420 777 716 123</a></span><br/>

          <span>E-mail: <a href="mailto:info@goldisinvest.cz">info@goldisinvest.cz</a></span><br/>


        </div></div>
        <ul className="copyright">
          <li>&copy; 2020 Goldis Invest s.r.o.</li>
          <li>
            Developed by <a href="mailto:webmaster@ricomtech.cz">Webmaster</a>
          </li>
        </ul>
      </div>
    </section>
  );
}
