import React from 'react';
import { Link } from 'gatsby';
import potrebujetezamestnance from '../assets/images/goldis/potrebujetezamestnance.png';
import vyhledavanizamestnance from '../assets/images/goldis/vyhledavanizamestnance.png';

export default function Nav({ onMenuToggle = () => {} }) {
  return (
    <nav id="menu">
      <div className="inner">
        <h2>Menu</h2>
        <ul className="links">
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="#ospolecnosti"
            >
              O společnosti

            </Link>
          </li>
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="#potrebujetezamestnance"
            >
             Potřebujete zaměstnance

            </Link>
          </li>
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="#vyhledavanizamestnance"
            >
              Vyhledávání zaměstnance

            </Link>
          </li>
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="#zajistimeprovas"
            >
              Zajistíme pro Vás

            </Link>
          </li>
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="#financnipodminky"
            >
              Finanční podmínky

            </Link>
          </li>
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="#reference"
            >
             Reference

            </Link>
          </li>

          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="#kontakt"
            >
              Kontakt

            </Link>
          </li>
        </ul>
        <a
          className="close"
          onClick={e => {
            e.preventDefault();
            onMenuToggle();
          }}
          href="#menu"
        >
          {''}
        </a>
      </div>
    </nav>
  );
}
